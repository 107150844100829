import { set, cloneDeep } from 'lodash';
import { Field } from '../../typescript/types/FieldComponentProps';

type NestedFields = {
  [key: number]: Field['value'] | NestedFields | NestedFields[];
};

interface UpdateDeep {
  <T extends object>(
    prevFields: T,
    field: Field['name'],
    value: Field['value'] | NestedFields
  ): T;
}

export const FIELD_MODES = {
  EDIT: 'edit',
  VIEW: 'view',
};

export const updateDeep: UpdateDeep = <T extends object>(
  prevFields: T,
  field: Field['name'],
  value: Field['value'] | NestedFields
): T => {
  const updatedFields = cloneDeep(prevFields);
  set(updatedFields, field, value);
  return updatedFields;
};

// export const handleFieldChange: FieldsDeepUpdate = (fieldName, value, setFields) => {
//   setFields((prevFields) => {
//     return updateDeep(prevFields, fieldName, value);
//   });
// };
